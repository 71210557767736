import api from '../../../globals/client';
import config from '../../../config/app.config';

var promise;

export default class GuestReservationsService {
    list(params) {
        promise =  api.client.get(config.end_point.guest.reservations + '?' + params);
        return promise;
    }

    cancel(data) {
        promise =  api.client.post(config.end_point.guest.reservation_update, data);
        return promise;
    }

    show(id) {
        promise =  api.client.get(config.end_point.guest.reservations + '/' + id);
        return promise;
    }

    update(id, payload) {
        promise =  api.client.put(config.end_point.guest.reservations + '/' + id, payload);
        return promise;
    }

    store(data) {
        promise =  api.client.post(config.end_point.guest.reservations, data);
        return promise;
    }

    fee(data){
        promise =  api.client.get(config.end_point.guest.reservations+"/get-fee",{params:data});
        return promise;
    }

    getPaymentSetting(data) {
        promise = api.client.get(config.end_point.facility.system_configs, {params: data});
        return promise;
    }

    reservable(data) {
        promise = api.client.post(config.end_point.guest.reservable, data);
        return promise;
    }
}

export const guestReservationsService = new GuestReservationsService();
