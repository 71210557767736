import {reservation_status} from "@/utils/mockup";
import {guestReservationsService} from "@/services/guest/reservations/reservations.service";
import {alertService} from "@/services/alert/alert.service";

export default {
    data: () => {
        return {
            confirm: {},
            confirmReady: false,
            entered: {},
            enteredReady: false,
            exited: {},
            exitedReady: false,
            cancelled: {},
            cancelledReady: false,
            reservation: {},
            reservation_status: reservation_status,
            filter: {
                page: 1
            },
            api_url: process.env.VUE_APP_API_URL,
        }
    },
    async mounted() {
        this.confirmList()
        this.enteredList()
        this.exitedList()
        this.cancelledList()
    },
    methods: {

        async confirmList() {

            this.confirmReady = false
            await guestReservationsService.list('paginate=1&per_page=10&status=0').then((response) => {
                this.confirm = response.data
                this.confirmReady = true
            }).catch((error) => {
            });

        },

        async enteredList() {

            this.enteredReady = false
            await guestReservationsService.list('paginate=1&per_page=10&status=1').then((response) => {
                this.entered = response.data
                this.enteredReady = true
            }).catch((error) => {
            });

        },

        async exitedList() {

            this.exitedReady = false
            await guestReservationsService.list('paginate=1&per_page=10&status=2').then((response) => {
                this.exited = response.data
                this.exitedReady = true
            }).catch((error) => {
            });

        },

        async cancelledList() {

            this.cancelledReady = false
            await guestReservationsService.list('paginate=1&per_page=10&status=3').then((response) => {
                this.cancelled = response.data
                this.cancelledReady = true
            }).catch((error) => {
            });

        },

        showCancelModal(data) {
            this.reservation = data
            this.$bvModal.show('cancel-modal')
        },

        hideCancelModal() {
            this.reservation = {}
            this.$bvModal.hide('cancel-modal')
        },

        async cancelReservation(id) {
            alertService.loading()
            await guestReservationsService.cancel({
                reservation_id: id,
                status: this.reservation_status.cancelled,
            }).then((response) => {
                this.hideCancelModal()
                this.confirmList()
                this.cancelledList()
                alertService.success(this.$t('guest.reservation.reservation-alert'), this.$t('guest.reservation.cancelled-successfully'));
            }).catch((error) => {
                alertService.close()
            });

        },

    }

}
